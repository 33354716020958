<template>
  <el-dialog
    title=" "
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="cancel"
    :append-to-body="true">
    <div class="left fontSize16">{{$t('lang.stopLiveConfirm')}}</div>
    <div class="left fontSize14">
      <el-checkbox v-model="isChecked"></el-checkbox>{{$t('lang.stopLiveCheck')}}
    </div>

    <span
      slot="footer"
      class="dialog-footer">
      <el-button @click="cancel">{{ $t('lang.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="confirm">{{ $t('lang.Ok') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'shareConfirm',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogVisible: this.show,
      isChecked: false
    }
  },
  watch: {
    show: {
      handler (newVal) {
        this.dialogVisible = newVal
      },
      deep: false
    }
  },
  methods: {
    cancel () {
      this.$emit('confirmFun', false)
      this.$emit('update:show', false)
    },
    confirm () {
      this.$emit('confirmFun', true)
      if (this.isChecked) {
        const params = {
          pn: 'shareTransmission',
          content: false
        }
        this.axios.post('/ccp/tvucc-user/userDevice/setPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        }).catch(() => {})
      }
      this.$emit('update:show', false)
      const lang = window.localStorage.getItem('lang')
      let data = 'Stop'
      if (lang == 'zh') {
        data = '停止'
      }
      this.$parent.stopOption(data)
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  width: 500px !important;
  height: 201px;
  background: #252525;
  box-shadow: 0px 0px 15px 0px rgba(16, 4, 0, 0.72);
  border-radius: 8px;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #252525 !important;
  height: 40px !important;
  line-height: 40px !important;
  background: #252525 !important;
}
::v-deep .el-dialog__body {
  height: 90px !important;
  .fontSize16 {
    font-size: 16px;
    color: #dfdfdf;
    padding-bottom: 15px;
    width: 100%;
    text-align: left;
    line-height: 10px;
  }
  .fontSize14 {
    font-size: 14px;
    color: #bcbcbc;
    width: 100%;
    text-align: left;
    line-height: 10px;
  }
}
::v-deep .el-dialog__footer {
  padding: 15px 0 20px !important;
}
::v-deep .el-checkbox .el-checkbox__inner {
  background: #252525 !important;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #666666;
  margin-right: 8px;
}
::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #666;
}
::v-deep .el-dialog__footer {
  float: right;
  height: 30px !important;
  margin-right: 20px;
}
::v-deep .el-button {
  float: left !important;
  max-width: 100px !important;
  width: 100px !important;
}
::v-deep .el-dialog__body {
  padding: 20px 20px !important;
}
</style>
