import { apiUrl } from '@/api.config.js'

/* 和R相关的设置都在这个配置文件里边 */
export function Receiver () {
  return {
    Channel: {
      channelNumber: apiUrl() + 'getChannelNumber' // 获取Receiver的声道数
    },
    GLinkEncoder: {
      encoderInfo: apiUrl() + 'glinkEncoder' // 用来查询gridEncoder的初始信息
    },
    GridEncoder: {
      encoderInfo: apiUrl() + 'gridEncoder', // 用来查询gridEncoder的初始信息
      encoderSource: apiUrl() + 'gridEncoder_source', // 设置encoder source类型
      encoderCodec: apiUrl() + 'gridEncoder_codec' // 设置encoder 编码
    },
    SmartCaption: {
      smartCaptionInfo: apiUrl() + 'smartCaption', // 用来获取字幕的配置信息
      language: apiUrl() + 'smartCaption_language', // 获取字幕的语言列表
      setSmartCaptionInfo: apiUrl() + 'smartCaption_setSmartCaptionInfo', // 设置字幕配置
      getScheduled: apiUrl() + 'smartCaptio_getScheduled',
      setScheduled: apiUrl() + 'smartCaptio_setScheduled',
      getReplace: apiUrl() + 'smartCaptio_getReplace',
      setReplace: apiUrl() + 'smartCaptio_setReplace'
    },
    VisionTag: {
      visionTagInfo: apiUrl() + 'visionTag', // 用来获取人脸识别的配置信息
      setVisionTag: apiUrl() + 'visionTag_setVisionTag' // 设置人脸识别的配置信息
    },
    AutoRecord: {
      autoRecordInfo: apiUrl() + 'autoRecord', // 获取配置信息
      configInfo: apiUrl() + 'autoRecord_configInfo', // 获取autodownload config模块里面的信息
      setAutoRecord: apiUrl() + 'autoRecord_setAutoRecord', // 这只autodownload参数
      getEncoderInfo: apiUrl() + 'autoRecord_getEncoderInfo',
      setEncoderInfo: apiUrl() + 'autoRecord_setEncoderInfo',
      getProfileList: apiUrl() + 'autoRecord_getProfileList',
      queryProfileInfo: apiUrl() + 'autoRecord_queryProfileInfo',
      deleteProfile: apiUrl() + 'autoRecord_deleteProfile',
      updateProfileInfo: apiUrl() + 'autoRecord_updateProfileInfo',
      creatProfile: apiUrl() + 'autoRecord_creatProfile',
      getProfileOptions: apiUrl() + 'record_getProfileOptions'
    },
    ExternalEncoder: {
      externalEncoderInfo: apiUrl() + 'externalEncoder', // 获取external信息
      setEncoder: apiUrl() + 'externalEncoder_setEncoder', // 设置encoder开启关闭
      setEncoderInfo: apiUrl() + 'externalEncoder_setEncoderInfo', // 设置encoder里面各参数
      setCBR: apiUrl() + 'externalEncoder_setCBR', // 设置encoder里面各参数
      setCopierInfo: apiUrl() + 'externalEncoder_setCopierInfo', // 设置copier各参数
      startPushStream: apiUrl() + 'externalEncoder_startPushStream', // 设置copier各参数
      setSharedMemory: apiUrl() + 'externalEncoder_setSharedMemory'
    },
    AutoDownload: {
      autoDownloadInfo: apiUrl() + 'autoDownload', // 获取autoDownload信息
      setEnable: apiUrl() + 'autoDownload_setEnable' // 设置autodownload是否开启
    },
    ProgressiveDownload: {
      progressiveDownloadInfo: apiUrl() + 'progressiveDownload', // ProgressiveDownload
      setProgressiveDownload:
        apiUrl() + 'progressiveDownload_setProgressiveDownload' // 设置ProgressiveDownload配置
    },
    SDIOutput: {
      enable: apiUrl() + 'sdi_enable', // 获取SDI Output是否启用
      cardNunber: apiUrl() + 'sdi_cardNumber', // 获取Card ID
      format: apiUrl() + 'sdi_format', // 获取视频制式
      videoAspect: apiUrl() + 'sdi_videoAspect', // 获取视频分辨率
      channel: apiUrl() + 'sdi_channel', // 获取视频声道数
      output12db: apiUrl() + 'sdi_output12db', // 获取12db是否启用
      asf: apiUrl() + 'sdi_asf', // 获取asf是否启用
      setlastframeAsf: apiUrl() + 'sdi_lastframeAsf', // 设置lastframe和asf
      setEnable: apiUrl() + 'sdi_setEnable', // 设置是否启用
      setVideoWidth: apiUrl() + 'sdi_setVideoWidth', // 设置视频分辨率
      setCardNumber: apiUrl() + 'sdi_setCardNumber', // 设置Card ID
      setOutoputFormat: apiUrl() + 'sdi_setOutoputFormat', // 设置视频输出格式
      setLastSeconds: apiUrl() + 'sdi_setLastSeconds', // 设置lastSeconds
      setChannel: apiUrl() + 'sdi_setChannel', // 设置视频声道数
      set12db: apiUrl() + 'sdi_set12db', // 设置12是否启用
      getSDISettingList: apiUrl() + 'sdi_getSettingInfo',
      videoFormat: apiUrl() + 'sdi_videoformat',
      logoParams: apiUrl() + 'sdi_logoParams',
      switchLogoParams: apiUrl() + 'sdi_switchLogoParams',
      changeSDIInfo: apiUrl() + 'sdi_changeSDIInfo'
    },
    MediaDevice: {
      getMediaDeviceInfo: apiUrl() + 'mediaDevice', // 获取设备列表
      setMediaDeviceInfo: apiUrl() + 'mediaDevice_setMediaDeviceInfo', // 设置设备
      getMediaDeviceVoip: apiUrl() + 'voip', // voip
      getGridDeviceVoip: apiUrl() + 'voip', // voip
      getMediaDeviceIFB: apiUrl() + 'ifb' // ifb
    },
    Record: {
      getRecordInfo: apiUrl() + 'record', // 获取record信息哪天有clip片段
      getFtpList: apiUrl() + 'record_getFTPList', // 获取FTP文件列表
      getDownloadList: apiUrl() + 'record_getDownloadList', // 获取download文件列表
      downloadAll: apiUrl() + 'record_downloadAll', // 下载所有clip片段
      download: apiUrl() + 'record_download', // 下载clip片段
      previewClip: apiUrl() + 'record_previewClip', // 预览clip片段
      stopPreviewClip: apiUrl() + 'record_stopPreviewClip', // 停止clip片段预览
      previewDownloadClip: apiUrl() + 'record_previewDownloadClip', // 预览下载的视频文件
      previewFTP: apiUrl() + 'record_previewFTP', // 预览FTP文件
      stopDownloadPreview: apiUrl() + 'record_stopDownloadPreview', // 停止下载clip片段预览
      startFileDownlaod: apiUrl() + 'record_startFileDownlaod', // 停止file clip片段预览
      stopFileDownlaod: apiUrl() + 'record_stopFileDownlaod', // 停止文件下载
      downloadFileDelete: apiUrl() + 'record_downloadFileDelete', // 删除文件
      ftpFileDelete: apiUrl() + 'record_ftpFileDelete', // 删除ftp文件
      deleteAllDownload: apiUrl() + 'record_deleteAllDownload', // 删除downloadlist里面的全部任务
      deleteAllFtp: apiUrl() + 'record_deleteAllFtp', // 删除ftp的全部文件
      getFileDownloadList: apiUrl() + 'record_getFileDownloadList', // 获取download list
      exportFile: apiUrl() + 'record_exportFile', // 到处视频文件
      deleteDownloadFile: apiUrl() + 'record_deleteDownloadFile', // 删除下载文件
      deleteAllDownloadFile: apiUrl() + 'record_deleteAllDownloadFile', // 删除所有download list里面的文件
      stopFtpPreview: apiUrl() + 'record_stopFtpPreview',
      getThumbnailTl: apiUrl() + 'record_getThumbnailTl'
    },
    configJson: {
      getConfigJsonInfo: apiUrl() + 'configJson', // 获取configJosn信息
      getVideoInfo: apiUrl() + 'VideoInfo', // 获取播放相关信息
      getFeatureInfo: apiUrl() + 'featureInfo',
      getFeatureList: apiUrl() + 'getFeatureList',
      setFeatureInfo: apiUrl() + 'setFeatureInfo',
      getIPandPort: apiUrl() + 'getIPandPort',
      setIpandPort: apiUrl() + 'setIpandPort',
      setSDIOpen: apiUrl() + 'setSDIOpen',
      getIpandPort: apiUrl() + 'getIpandPort',
      setIPandPort: apiUrl() + 'setIPandPort',
      portAddList: apiUrl() + 'portAddList',
      setAddressList: apiUrl() + 'setAddressList'
    },
    youTobeAnalyze: {
      analyzeUrl: apiUrl() + 'analyzeUrl', // 验证youtube的url
      updataDevice: apiUrl() + 'updataDevice',
      deleteDevice: apiUrl() + 'deleteDevice'
    },
    setSlot: {
      setSlotIndex: apiUrl() + 'setSlotIndex',
      setSlotReset: apiUrl() + 'setSlotReset'
    },
    setR: {
      setLocation: apiUrl() + 'setLocation'
    },
    setVideo: {
      setCbrVbr: apiUrl() + 'setCbrVbr',
      setVideoDynamic: apiUrl() + 'setVideoDynamic',
      setVideoWorkmode: apiUrl() + 'setVideoWorkmode',
      setUserInfo: apiUrl() + 'setUserInfo'
    },
    getRemainInfo: {
      remainInfo: apiUrl() + 'StorageOperation'
    },
    getQualityUrl: {
      qualityUrl: apiUrl() + 'UCStatusOperation'
    },
    getDevicePosition: {
      getTPosition: apiUrl() + 'GetAppConfigJson'
    },
    getRInfo: {
      recTimeRemain: apiUrl() + 'recTimeRemain',
      playBackModel: apiUrl() + 'playBackModel'
    },
    TokenService: {
      getCoreInfo: apiUrl() + 'token_getCoreInfo',
      creatToken: apiUrl() + 'token_creatToken',
      getToken: apiUrl() + 'token_getToken',
      getTokenValidTime: apiUrl() + 'token_getTokenValidTime',
      getTokenInfo: apiUrl() + 'token_getTokenInfo',
      setTokenInfo: apiUrl() + 'token_setTokenInfo',
      setTokenValidTime: apiUrl() + 'token_setTokenValidTime',
      getIP: apiUrl() + 'token_getIP',
      getRInfo: apiUrl() + 'token_getRInfo',
      getServerToken: apiUrl() + 'token_getServerToken',
      deleteServerToken: apiUrl() + 'token_deleteServerToken'
    },
    VLANSetting: {
      getRouterInfo: apiUrl() + 'VLAN_getRouterInfo',
      updateRouterPort: apiUrl() + 'VLAN_updateRouterPort',
      getInterfaceInfos: apiUrl() + 'VLAN_getInterfaceInfos',
      startRouter: apiUrl() + 'VLAN_startRouter',
      stopRouter: apiUrl() + 'VLAN_stopRouter',
      isClient: apiUrl() + 'VLAN_isClient',
      connectList: apiUrl() + 'VLAN_connectList'
    },
    General: {
      getGenertalInfo: apiUrl() + 'getGenertalInfo',
      changeGenertalInfo: apiUrl() + 'changeGenertalInfo'
    },
    PassThrough: {
      getPassthroughInfo: apiUrl() + 'getPassthroughInfo',
      savePassthoughInfo: apiUrl() + 'savePassthoughInfo',
      saveAndStartPushing: apiUrl() + 'saveAndStartPushing',
      stopPushing: apiUrl() + 'stopPushing'
    },
    fileStorage: {
      getDestination: apiUrl() + 'getDestination',
      getUploadList: apiUrl() + 'getUploadList',
      deleteFile: apiUrl() + 'deleteFile',
      getProfiles: apiUrl() + 'getProfiles',
      applyProfile: apiUrl() + 'applyProfile',
      getModule: apiUrl() + 'getModule',
      getStatus: apiUrl() + 'getStatus',
      getRecordList: apiUrl() + 'getRecordList',
      retryCutrrent: apiUrl() + 'retryCutrrent',
      getRegionAndBucket: apiUrl() + 'getRegionAndBucket',
      saveFTP: apiUrl() + 'saveFTP',
      updateFTP: apiUrl() + 'updateFTP'
    },
    getHttpsPort: apiUrl() + 'getHttpsPort'
  }
}
export const receviceParams = {
  Channel: {
    channelNumber: {
      // 获取Receiver的声道数
      categoryId: '2152867840',
      operationType: '101'
    }
  },
  GLinkEncoder: {
    encoderInfo: {
      // 用来查询gLink的初始信息
      operationType: '100',
      categoryId: '2152871424'
    }
  },
  GridEncoder: {
    encoderInfo: {
      // 用来查询gridEncoder的初始信息
      operationType: '200',
      categoryId: '2152868864'
    },
    encoderSource: {
      // 设置encoder source类型
      operationType: '400',
      categoryId: '2152868864'
    },
    encoderCodec: {
      // 设置encoder 编码
      operationType: '500',
      categoryId: '2152868864'
    }
  },
  SmartCaption: {
    smartCaptionInfo: {
      // 用来获取字幕的配置信息
      operationType: '101',
      categoryId: '2152868608'
    },
    language: {
      // 获取字幕的语言列表
      operationType: '102',
      categoryId: '2152868608'
    },
    setSmartCaptionInfo: {
      // 设置字幕配置
      operationType: '201',
      categoryId: '2152868608'
    },
    getReplace: {
      // 获取替换字幕信息
      operationType: '300',
      categoryId: '2152868608'
    },
    setReplace: {
      // 设置替换字幕信息
      operationType: '301',
      categoryId: '2152868608'
    },
    getScheduled: {
      // 获取Schedule
      operationType: '400',
      categoryId: '2152868608'
    },
    setScheduled: {
      // 设置Schedule
      operationType: '401',
      categoryId: '2152868608'
    }
  },
  VisionTag: {
    visionTagInfo: {
      // 用来获取人脸识别的配置信息
      operationType: '101',
      categoryId: '2152869120'
    },
    setVisionTag: {
      // 设置人脸识别的配置信息
      operationType: '201',
      categoryId: '2152869120'
    }
  },
  AutoRecord: {
    autoRecordInfo: {
      operationType: '100',
      categoryId: '2152870400'
    }, // 获取配置信息
    autoRecord: {
      operationType: '300',
      categoryId: '2152870400'
    },
    configInfo: {
      operationType: '200',
      categoryId: '2152870400'
    }, // 获取autodownload config模块里面的信息
    setAutoRecord: {
      operationType: 301,
      categoryId: '2152870400'
    }, // 这只autodownload参数
    getProfileList: {
      operationType: 201,
      categoryId: '2152870400'
    },
    queryProfileInfo: {
      operationType: 400,
      categoryId: '2152870400'
    },
    deleteProfile: {
      operationType: 403,
      categoryId: '2152870400'
    },
    updateProfileInfo: {
      operationType: 402,
      categoryId: '2152870400'
    },
    creatProfile: {
      operationType: 401,
      categoryId: '2152870400'
    },
    getProfileOptions: {
      operationType: 404,
      categoryId: '2152870400'
    }
  },
  ExternalEncoder: {
    externalEncoderInfo: {
      operationType: '101',
      categoryId: '2152870144'
    }, // 获取external信息
    setEncoder: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder开启关闭
    setEncoderInfo: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder里面各参数
    setCBR: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder里面各参数
    setCopierInfo: {
      operationType: '401',
      categoryId: '2152870144'
    }, // 设置copier各参数
    stopPushStream: {
      operationType: '403',
      categoryId: '2152870144'
    }, // 设置copier各参数
    startPushStream: {
      operationType: '402',
      categoryId: '2152870144'
    }
  },
  AutoDownload: {
    autoDownloadInfo: {
      operationType: '101',
      categoryId: '2152867328'
    }, // 获取autoDownload信息
    setEnable: {
      operationType: '200',
      categoryId: '2152867328'
    } // 设置autodownload是否开启
  },
  ProgressiveDownload: {
    progressiveDownloadInfo: {
      operationType: '101',
      categoryId: '2152874752'
    }, // ProgressiveDownload
    setProgressiveDownload: {
      operationType: '200',
      categoryId: '2152874752'
    } // 设置ProgressiveDownload配置
  },
  SDIOutput: {
    enable: {
      operationType: '403',
      categoryId: '2152857600'
    }, // 获取SDI Output是否启用
    setlastframeAsf: {
      operationType: '400',
      categoryId: '2152857600'
    }, // 设置lastframe和asf
    getSDISettingList: {
      operationType: '403',
      categoryId: '2152857600'
    },
    getSDISettingList78: {
      operationType: '408',
      categoryId: '2152857600'
    },
    videoFormat: {
      operationType: '105',
      categoryId: '2152857600'
    },
    logoParams: {
      operationType: '204',
      categoryId: '2152858368'
    },
    switchLogoParams: {
      operationType: '203',
      categoryId: '2152858368'
    },
    changeSDIInfo: {
      operationType: '402',
      categoryId: '2152857600'
    },
    changeSDIInfo78: {
      operationType: '409',
      categoryId: '2152857600'
    }
  },
  MediaDevice: {
    getMediaDeviceInfo: {
      operationType: '1300',
      categoryId: '2152861696' // 待定
    }, // 获取设备列表
    setMediaDeviceInfo: {
      operationType: '1301',
      categoryId: '2152861696'
    }, // 设置设备
    getMediaDeviceVoip: {
      operationType: '1200',
      categoryId: '2152861696'
    },
    getGridDeviceVoip: {
      operationType: '800',
      categoryId: '2152861696'
    },
    getMediaDeviceIFB: {
      operationType: '1101',
      categoryId: '2152861696'
    }
  },
  Record: {
    getRecordInfo: {
      operationType: '200',
      categoryId: '2152866304'
    }, // 获取record信息哪天有clip片段
    /*
     * getFtpList: {},		//获取FTP文件列表
     * getDownloadList: {},		//获取download文件列表
     */
    downloadAll: {
      operationType: '202',
      categoryId: '2152866304'
    }, // 下载所有clip片段
    download: {
      operationType: '201',
      categoryId: '2152866304'
    }, // 下载clip片段
    previewClip: {
      operationType: '203',
      categoryId: '2152866304'
    }, // 预览clip片段
    stopPreviewClip: {
      operationType: '204',
      categoryId: '2152866304'
    }, // 停止clip片段预览
    previewDownloadClip: {
      operationType: '202',
      categoryId: '2152866560'
    }, // 预览下载的视频文件
    previewFTP: {
      operationType: '400',
      categoryId: '2152866560'
    }, // 预览FTP文件
    stopDownloadPreview: {
      operationType: '203',
      categoryId: '2152866560'
    }, // 停止下载clip片段预览
    startFileDownlaod: {
      operationType: '200',
      categoryId: '2152866560'
    }, // 停止file clip片段预览
    stopFileDownlaod: {
      operationType: '201',
      categoryId: '2152866560'
    }, // 停止文件下载
    downloadFileDelete: {
      operationType: '204',
      categoryId: '2152866560'
    }, // 删除文件
    ftpFileDelete: {
      operationType: '402',
      categoryId: '2152866560'
    }, // 删除ftp文件
    deleteAllDownload: {
      operationType: '205',
      categoryId: '2152866560'
    }, // 删除downloadlist里面的全部任务
    deleteAllFtp: {
      operationType: '403',
      categoryId: '2152866560'
    }, // 删除ftp的全部文件
    getFileDownloadList: {
      operationType: '103',
      categoryId: '2152866560'
    }, // 获取download list
    exportFile: {
      operationType: '300',
      categoryId: '2152866560'
    }, // 到处视频文件
    deleteDownloadFile: {
      operationType: '500',
      categoryId: '2152866560'
    }, // 删除下载文件
    deleteAllDownloadFile: {
      operationType: '501',
      categoryId: '2152866560'
    }, // 删除所有download list里面的文件
    stopFtpPreview: {
      operationType: '401',
      categoryId: '2152866560'
    },
    getThumbnailTl: {
      operationType: '104',
      categoryId: '2152857600'
    }
  },
  configJson: {
    getConfigJsonInfo: {
      operationType: '104',
      categoryId: '2152857600'
    }, // 获取configJosn信息
    getVideoInfo: {
      categoryId: '2152857600',
      operationType: '102'
    },
    getFeatureInfo: {
      operationType: '900',
      categoryId: '2152857600'
    },
    // 7.5及以上R版本支持
    getFeatureList: {
      operationType: '901',
      categoryId: '2152857600'
    },
    setFeatureInfo: {
      operationType: '902',
      categoryId: '2152857600'
    },
    getIpandPort: {
      operationType: '403',
      categoryId: '2152857600'
    },
    setIPandPort: {
      operationType: '402',
      categoryId: '2152857600'
    },
    portAddList: {
      operationType: '1200',
      categoryId: '2152857600'
    },
    setAddressList: {
      operationType: '1201',
      categoryId: '2152857600'
    }
  },
  youTobeAnalyze: {
    analyzeUrl: {
      operationType: '500',
      categoryId: '2152862208'
    }, // 验证youtube的url
    updataDevice: {
      operationType: '400',
      categoryId: '2152862208'
    },
    deleteDevice: {
      operationType: '300',
      categoryId: '2152862208'
    }
  },
  setSlot: {
    setSlotIndex: {
      categoryId: '2152865792',
      OperationType: 202
    },
    setSlotReset: {
      categoryId: '2152865792',
      OperationType: 203
    }
  },
  setR: {
    isSetLocation: {
      operationType: '201',
      categoryId: '2152875008'
    },
    setLocation: {
      operationType: '200',
      categoryId: '2152875008'
    }
  },
  setVideo: {
    setCbrVbr: {
      categoryId: '2152866048',
      operationType: '201'
    },
    setVideoDynamic: {
      OperationType: '204',
      categoryId: '2152866048'
    },
    setVideoWorkmode: {
      OperationType: '200',
      categoryId: '2152866048'
    },
    setUserInfo: {
      categoryId: '2152866048',
      OperationType: '202'
    }
  },
  getRInfo: {
    recTimeRemain: {
      categoryId: '2152867584',
      OperationType: '101'
    },
    playBackModel: {
      categoryId: '2152857600',
      OperationType: '103'
    }
  },
  TokenService: {
    getCoreInfo: {
      categoryId: '2152867072',
      OperationType: '101'
    },
    creatToken: {
      categoryId: '2152867072',
      OperationType: '300'
    },
    creatToken77: {
      categoryId: '2152867072',
      OperationType: '301'
    },
    getToken77: {
      categoryId: '2152867072',
      OperationType: '301'
    },
    getToken: {
      categoryId: '2152867072',
      OperationType: '400'
    },
    getTokenValidTime: {
      categoryId: '2152867072',
      OperationType: '401'
    },
    getTokenInfo: {
      categoryId: '2152867072',
      OperationType: '402'
    },
    setTokenInfo: {
      categoryId: '2152867072',
      OperationType: '500'
    },
    setTokenValidTime: {
      categoryId: '2152867072',
      OperationType: '501'
    },
    getIP: {
      categoryId: '2152867072',
      OperationType: '600'
    },
    getIP77: {
      categoryId: '2152867072',
      OperationType: '602'
    },
    getRInfo: {
      categoryId: '2152867072',
      OperationType: '601'
    },
    getRInfo77: {
      categoryId: '2152867072',
      OperationType: '603'
    },
    getServerToken: {
      categoryId: '2152867072',
      OperationType: '700'
    },
    deleteServerToken: {
      categoryId: '2152867072',
      OperationType: '701'
    }
  },
  VLANSetting: {
    getRouterInfo: {
      categoryId: '2152875520',
      OperationType: '301'
    },
    updateRouterPort: {
      categoryId: '2152875520',
      OperationType: '300'
    },
    getInterfaceInfos: {
      categoryId: '2152875520',
      OperationType: '400'
    },
    startRouter: {
      categoryId: '2152875520',
      OperationType: '401'
    },
    stopRouter: {
      categoryId: '2152875520',
      OperationType: '402'
    },
    isClient: {
      categoryId: '2152875520',
      OperationType: '101'
    },
    connectList: {
      categoryId: '2152875520',
      OperationType: '404'
    }
  },
  General: {
    getGenertalInfo: {
      categoryId: '2152857600',
      OperationType: '404'
    },
    changeGenertalInfo: {
      categoryId: '2152857600',
      OperationType: '405'
    }
  },
  PassThrough: {
    getPassthroughInfo: {
      categoryId: '2152871936',
      OperationType: '101'
    },
    savePassthoughInfo: {
      categoryId: '2152871936',
      OperationType: '102'
    },
    saveAndStartPushing: {
      categoryId: '2152871936',
      OperationType: '103'
    },
    stopPushing: {
      categoryId: '2152871936',
      OperationType: '104'
    }
  },
  fileStorage: {
    getDestination: {
      categoryId: '2152875776',
      OperationType: '206'
    },
    getUploadList: {
      categoryId: '2152875776',
      OperationType: '203'
    },
    deleteFile: {
      categoryId: '2152875776',
      OperationType: '307'
    },
    getProfiles: {
      categoryId: '2152875776',
      OperationType: '202'
    },
    applyProfile: {
      categoryId: '2152875776',
      OperationType: '501'
    },
    getModule: {
      categoryId: '2152875776',
      OperationType: '207'
    },
    getStatus: {
      categoryId: '2152875776',
      OperationType: '208'
    },
    getRecordList: {
      categoryId: '2152875776',
      OperationType: '209'
    },
    retryCutrrent: {
      categoryId: '2152875776',
      OperationType: '600'
    },
    getRegionAndBucket: {
      categoryId: '2152875776',
      OperationType: '210'
    },
    saveFTP: {
      categoryId: '2152875776',
      OperationType: '306'
    },
    updateFTP: {
      categoryId: '2152875776',
      OperationType: '305'
    }
  },
  getHttpsPort: {
    categoryId: '2152857600',
    OperationType: '101'
  }
}
export default {
  Receiver,
  receviceParams
}
