<template>
  <el-dialog
    :title="$t('lang.gridTitle')"
    :visible.sync="openFlag"
    width="30%"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="cancel">
    <el-form
      :model="form"
      label-width="120px">
      <el-form-item
        class="nameItem"
        :label="$t('lang.searchT')">
        {{ confirmObj.name }}
      </el-form-item>
      <el-form-item :label="$t('lang.description')">
        <el-input
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 6}"
          :readonly="!hasCreatePerimission"
          placeholder="xxxxxx"
          v-model="form.condition"
          autocomplete="off"
        />
      </el-form-item>
      <div
        class="fontSize14"
        v-if="hasCreatePerimission || (!hasCreatePerimission && isCheckTime && form.endTime)">
        <el-checkbox
          :disabled="!hasCreatePerimission"
          v-model="isCheckTime">
        </el-checkbox>
        <span>{{$t('lang.endTime')}}</span>
        <el-date-picker
          :readonly="!hasCreatePerimission"
          popper-class="pick_date_timebk"
          v-show="isCheckTime"
          v-model="form.endTime"
          :picker-options="pickerOptions"
          value-format="timestamp"
          type="datetime">
        </el-date-picker>
      </div>
      <div
        class="fontSize14"
        v-if="confirmObj.checkBehavior">
        <el-checkbox v-model="isChecked"></el-checkbox><span>{{$t('lang.stopLiveCheck')}}</span>
      </div>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
      v-if="hasCreatePerimission || !hasCreatePerimission && this.confirmObj.checkBehavior"
    >
      <el-button
        v-if="hasCreatePerimission && showSlugInfo"
        @click.stop="stopEvent"
        type="danger">{{ $t('lang.end') }}</el-button>
      <el-button @click.stop="cancel">{{ $t('lang.cancel') }}</el-button>
      <el-button
        type="info"
        v-if="this.confirmObj.checkBehavior || showSlugInfo"
        :disabled="disableSave"
        @click.stop="confirm('update')">{{ $t('lang.save') }}</el-button>
      <el-button
        v-if="hasCreatePerimission"
        type="success"
        :disabled="disableStart"
        @click.stop="confirm('start')">{{ $t('lang.start') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import oUtils from '@/assets/js/utils'
import Bus from '@/assets/js/vueBus.js'

export default {
  props: {
    confirmObj: {
      type: Object,
      default: () => {
        return {
          peerId: '', // Grid或Pack或Anywhere的peerId
          showFlag: true, // 是否显示弹框
          name: '',
          checkBehavior: true,
          hasSlugPerimission: false
        }
      }
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      descriptionFlag: false, // 是否显示description的弹框
      isChecked: false,
      isCheckTime: false,
      form: {
        condition: '',
        endTime: null
      },
      originInfo: null,
      hasCreatePerimission: false,
      showSlugInfo: true // 是否是onGoingEvent
    }
  },
  computed: {
    ...mapState({
      State: state => state
    }),
    openFlag () {
      const flag = !!(this.confirmObj.showFlag && this.descriptionFlag)
      return flag
    },
    changedSlug () {
      if (!this.showSlugInfo && this.form.condition == '' || this.showSlugInfo && this.originInfo && this.originInfo.condition == this.form.condition) {
        return false
      }
      return true
    },
    changedEndTime () {
      const endTime = this.isCheckTime ? this.form.endTime : 9999999999999
      if (!this.showSlugInfo && !this.isCheckTime || this.showSlugInfo && this.originInfo && this.originInfo.endTime == endTime) {
        return false
      }
      return true
    },
    disableStart () {
      // 用户只有修改了slug信息才可以点击Start
      if (!this.changedSlug) {
        return true
      }
      return false
    },
    justChangeBehavior () {
      const flag = !!(!this.changedSlug && !this.changedEndTime)
      if (this.confirmObj.checkBehavior && flag && this.isChecked) {
        return true
      }
      return false
    },
    disableSave () {
      const flag = !!(!this.changedSlug && !this.changedEndTime)
      // 用户未修改endTime和slug信息时不允许点击save
      if (this.confirmObj.checkBehavior) {
        if (flag && this.isChecked || !flag && this.showSlugInfo) {
          return false
        } else {
          return true
        }
      } else {
        return flag
      }
    }
  },
  async created () {
    // 点击mySource界面图标Pack和Anywhere，不需要check用户行为记录
    if (!this.confirmObj.checkBehavior) {
      this.hasCreatePerimission = this.confirmObj.hasSlugPerimission
      this.confirmObj.showFlag = true
      this.descriptionFlag = true
      this.getGridSlugInfo('icon')
      return
    }
    // 点击start及stop时需要先验证用户行为记录，然后验证是否有CreateEvent权限
    const showEvent = await this.judgeEventConfirmOpen()
    if (showEvent) this.checkCreatePerimission()
  },
  methods: {
    checkCreatePerimission () {
      const params = {
        packList: [],
        anyWhereList: []
      }
      if (this.confirmObj.sourceType == 'Pack' || this.confirmObj.sourceType == 'Grid') {
        params.packList = [this.confirmObj.peerId]
      } else if (this.confirmObj.sourceType == 'Anywhere') {
        params.anyWhereList = [this.confirmObj.peerId]
      }
      this.axios.post('/ccp/tvucc-user/userDevice/isUserOwnerByListPeerId', params, {
        contentType: 'application/json;charset=UTF-8',
        isCancelToken: true
      }).then(res => {
        const data = res.data.result[0]
        this.hasCreatePerimission = data.creatEvent
        this.descriptionFlag = !!(data.creatEvent || data.hasEndTime && !data.hasExpire && !data.creatEvent && data.hasSlug)
        if (!data.hasExpire && data.hasSlug && this.descriptionFlag) {
          this.getGridSlugInfo()
        } else {
          this.showSlugInfo = false
        }
      }).catch(() => {})
    },
    getGridSlugInfo (tag) {
      const params = {
        sourceId: this.confirmObj.peerId
      }
      this.axios.post('/ccp/tvucc-device/receiver/getSlugByGridId', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        const resultFlag = res.data.errorCode == '0x0' && res.data.result && res.data.result[0]
        this.showSlugInfo = resultFlag
        if (resultFlag) {
          const result = res.data.result[0]
          const time = new Date()
          const nowDate = time.getTime()
          const hasExpire = result.endTime < nowDate
          this.showSlugInfo = tag && tag == 'icon' ? !!(result.slug && !hasExpire) : resultFlag
        } else {
          this.showSlugInfo = tag && tag == 'icon' ? false : resultFlag
        }
        if (this.showSlugInfo) {
          const result = res.data.result[0]
          // 当后台返回9999999999999时，代表没有传endTime
          this.originInfo = {
            condition: result.slug,
            endTime: result.endTime
          }
          this.form.condition = result.slug
          this.form.id = result.id
          this.isCheckTime = result.endTime != 9999999999999
          this.form.endTime = result.endTime == 9999999999999 ? null : result.endTime
        }
      }).catch(() => {
        this.showSlugInfo = false
      })
    },
    // 用户行为看是否弹出更新slug信息的弹框
    async judgeEventConfirmOpen () {
      const params = {
        pn: 'gridDescriptionConfirm'
      }
      let flag = false
      await this.axios.post('/ccp/tvucc-user/userDevice/listPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (res.data.errorCode == '0x0') {
          flag = res.data.result && res.data.result[0] && res.data.result[0].content == 'true'
        } else {
          flag = false
        }
      }).catch(() => {})
      return flag
    },
    // 添加用户行为记录
    setPreference () {
      const params = {
        pn: 'gridDescriptionConfirm',
        content: false
      }
      this.axios.post('/ccp/tvucc-user/userDevice/setPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (this.justChangeBehavior) this.cancel()
      }).catch(() => {
        if (this.justChangeBehavior) this.cancel()
      })
    },
    // 保存更新slug信息
    saveEventInfo (tag) {
      const params = {
        endTime: this.isCheckTime ? this.form.endTime : 9999999999999,
        sourceId: this.confirmObj.peerId,
        condition: this.form.condition
      }
      if (tag && tag == 'update') {
        params.id = this.form.id
      }
      const url = '/ccp/tvucc-device/receiver/sendSlugToSearch'
      this.axios
        .post(url, params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            if (tag && (tag == 'stop' || tag == 'start') && !this.confirmObj.checkBehavior) {
              setTimeout(() => {
                Bus.$emit('updateEventIcon', { peerId: params.sourceId, tag: tag })
              }, 1000)
            }
          } else {
            oUtils.ToTip(res.data.errorCode, 'info', res.data.errorInfo)
          }
          this.cancel()
        }).catch(() => {})
    },
    // 保存操作
    confirm (tag) {
      // 如果勾选了endTime，那么endTime不能为空
      if (this.isCheckTime && !this.form.endTime) {
        this.$message({
          type: 'info',
          message: this.$t('lang.enterEndTime')
        })
        return
      }
      // endTime不能小于当前时间
      const time = new Date()
      const nowDate = time.getTime()
      if (this.isCheckTime && this.form.endTime && this.form.endTime <= nowDate + 5000) {
        this.$message.info(this.$t('lang.endTimeMoreCurTime'))
        return
      }
      // description不能为空
      const desc = this.form.condition
      if (desc.trim() == '' && !this.justChangeBehavior) {
        this.$message.info(this.$t('lang.enterDescription'))
        return
      }
      let tipInfo = ''
      if (tag && tag == 'update' && !this.justChangeBehavior) { // onGoingEvent更新
        tipInfo = this.$t('lang.updateEvent')
      }
      if (tag && tag == 'start' && this.showSlugInfo) { // 属于onGoingEvent且更新
        tipInfo = this.$t('lang.replaceEvent')
      }
      if (tag && tag == 'start' && !this.showSlugInfo) {
        tipInfo = this.$t('lang.startNewEvent')
      }
      if (tipInfo != '') {
        this.openConfirm(tipInfo, tag)
      } else {
        if (!this.justChangeBehavior) this.saveEventInfo(tag)
        if (this.isChecked) this.setPreference()
      }
    },
    stopEvent () {
      const tip = this.$t('lang.stopEvent')
      this.form.condition = ''
      this.openConfirm(tip, 'stop')
    },
    openConfirm (tip, tag) {
      this.$confirm(tip, '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.Cancel'),
        iconClass: 'closeTipIcon',
        customClass: 'createEventBox',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.saveEventInfo(tag)
        if (this.isChecked) this.setPreference()
      }).catch(() => {})
    },
    // 关闭窗口
    cancel () {
      this.confirmObj.showFlag = false
      this.confirmObj.peerId = ''
      this.confirmObj.sourceType = ''
      this.descriptionFlag = false
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  width: 580px !important;
  height: fit-content;
  max-height: 500px;
  background: #252525;
  box-shadow: 0px 0px 15px 0px rgba(16, 4, 0, 0.72);
  border-radius: 8px;
}
.el-form {
  padding-top: 10px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid #353535 !important;
  background: #252525 !important;
}
::v-deep .el-dialog__body {
  padding: 20px 20px 35px;
  .el-form {
    padding-top: 0;
    .el-form-item {
      margin-top: 5px;
      p {
        margin: 0;
      }
      .el-textarea__inner {
        max-height: 160px;
      }
      .el-form-item__label {
        text-align: left;
        font-size: 14px;
        font-weight: 600!important;
      }
      &.nameItem {
        .el-form-item__label,.el-form-item__content {
          color: #bcbcbc;
          font-weight: 500;
        }
      }
    }
  }
  .el-date-editor {
    width: calc(100% - 120px);
    margin-left: 13px;
    float: right;
  }
  .el-input__inner {
    background: #444;
    border-radius: 6px;
    color: #fff;
    border: 1px solid #353535;
  }
  .fontSize16 {
    font-size: 16px;
    color: #dfdfdf;
    padding-bottom: 15px;
    width: 100%;
    text-align: left;
    line-height: 10px;
  }
  .fontSize14 {
    font-size: 14px;
    width: 100%;
    text-align: left;
    line-height: 10px;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    span {
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog__footer {
  padding: 0 0 20px !important;
  .dialog-footer {
    text-align: right!important;
    margin-right: 20px;
  }
}
::v-deep .el-checkbox .el-checkbox__inner {
  background: #252525 !important;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #666666;
  margin-right: 8px;
}
::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #0c6!important;
  border-color: #0c6;
}
::v-deep .el-button {
  max-width: 80px !important;
  width: 80px !important;
  margin-left: 15px!important;
  padding: 10px;
  &.el-button--success.is-disabled {
    color: #252525;
    background: #3F5D44;
    border: 1px solid #3F5D44;
    &:hover {
      color: #252525;
      background: #3F5D44;
      border: 1px solid #3F5D44;
    }
  }
  &.el-button--default {
    color: #252525;
    background: #ccc;
    border: 1px solid #ccc;
  }
  &.el-button--info {
    color: #DBDBDB;
    background: #666;
    border: 1px solid #666;
    &.is-disabled {
      background: #6e6a6a;
      border: 1px solid #6e6a6a;
    }
  }
}
</style>
