export function apiUrl () {
  // let hostname = window.location.hostname
  const API_URL = '/ccp/tvucc-rws/receiver/command/'
  return API_URL
}

export function config () {
  const CONFIG_url = '/ccp/tvu-configT/configT/getUrl'
  return CONFIG_url
}

export function deviceInfo () {
  const DEVICE_INFO = '/ccp/tvucc-device/device/'
  return DEVICE_INFO
}

export function startOrStopVideo () {
  const START_VIDEO = '/ccp/tvucc-device/receiver/'
  return START_VIDEO
}

export function userDevice () {
  const USER_DEVICE = '/ccp/tvucc-user/userDevice/'
  return USER_DEVICE
}
