<template>
  <div class="takeButtonModule">
    <el-button
      size="small"
      type="success"
      ref="liveBtn"
      :disabled="showBtnStatus(currentSocektData)==0 || timeLockPage && timeLocked"
      :loading="allowClick"
      @click.stop="startVideo()"
      :title="$t(`${showBtnStatus(currentSocektData) == 2?'lang.Stop':parentComponent?'lang.goLive':'lang.Take'}`)"
      :class="showBtnStatus(currentSocektData)|BTNSTATUS">{{ $t(`${text}`) }}
    </el-button>
    <div class="hide">
      <slot></slot>
    </div>
    <el-dialog
      :title="$t('lang.modelTips')"
      :visible.sync="dialogVisible"
      width="30%"
      custom-class="greenColor">
      <span>{{ $t(`${alertMessage}`) }}</span>
      <span
        slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('lang.cancel') }}</el-button>
        <el-button type="primary">{{ $t('lang.Ok') }}</el-button>
      </span>
    </el-dialog>
    <ShareConfirm
      :show.sync="showFlag"
      @confirmFun="confirmFun"></ShareConfirm>
    <GridDescription
      v-if="confirmObj.showFlag && openOnGoingEvent"
      :confirmObj="confirmObj"></GridDescription>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { MySource } from '@/assets/api/mySource'
import oUtils from '@/assets/js/utils'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import ShareConfirm from '@/components/shareConfirm'
import GridDescription from '@/components/gridDescription'
import Qs from 'qs'
/*
 * 首先根据初始变量来执行不同的方法
 *      如果过没有选择其他设备，则按传进来的sourceItem里面的状态来显示
 *      如果有选择其他设备则根据当前所传id掉借口查询状态
 *      falg:用来表示时sourcelist还是rlist
 *      sourceItem是当前所选择的对象
 *      haveSelectDevice表示是否有选择过其他设备
 *      deviceList表示所选择的T和R的id
 */
export default {
  components: {
    ShareConfirm,
    GridDescription
  },
  props: {
    flag: {
      type: String,
      default: 'T'
    },
    sourceItem: {
      type: Object,
      default: () => {
        return { status: 0 }
      }
    },
    hadSelectDevice: {
      type: Boolean,
      default: false
    },
    deviceList: {
      type: String,
      default: ''
    },
    parentComponent: {
      type: String,
      default: ''
    },
    selectType: {
      type: String,
      default: ''
    },
    timeLocked: {
      type: Boolean,
      default: false
    },
    timeLockPage: {
      type: Boolean,
      default: false
    },
    socketRInfo: Object // 从R获取的实时信息
  },
  watch: {
    sourceItem: function (data) {
      this.currentSocektData = data
      this.showBtnStatus(data)
    },
    deviceList: async function (data) {
      if (data) {
        this.selectDeviceList = data.replace(';', ',')
        await this.getDeviceInfo(this.selectDeviceList)
        if ((this.selectType == 'LocalSDI' || !this.sourceObject) && this.flag === 'R' && this.receiverObject) await this.getLocalSDIInfo()
      }
    },
    hadSelectDevice: function (data) {
      this.selectOhterDevice = data
    },
    text: function (params) {
      this.allowClick = false
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      liveOnHoldFlag: state => state.featureFlag.liveOnHoldFlag
    }),
    text () {
      const status = this.showBtnStatus(this.currentSocektData)
      if (this.showLoading || this.showStopping) {
        let _str = ''
        if (this.showLoading && status == 2) {
          _str = 'lang.Stop'
          this.showLoading = false
        } else if (this.showLoading) {
          _str = this.parentComponent ? 'lang.goLive' : 'lang.Take'
        }
        if (this.showStopping && status == 1) {
          _str = this.parentComponent ? 'lang.goLive' : 'lang.Take'
          this.showStopping = false
        } else if (this.showStopping) {
          // 解决source被多个Rlive时的问题
          if (this.sourceObject) {
            _str = this.sourceObject.livePeerId != this.sourceItem.peerId ? 'lang.Stop' : 'lang.stopping'
          } else {
            _str = 'lang.Stop'
          }
        }
        return _str
      } else if (status == 0) {
        return this.parentComponent ? 'lang.goLive' : 'lang.Take'
      } else {
        return status == 2 ? 'lang.Stop' : this.parentComponent ? 'lang.goLive' : 'lang.Take'
      }
    },
    openOnGoingEvent () {
      return this.$store.state.common.baseUrl.openOnGoingEvent
    }
  },
  data () {
    return {
      showLoading: false,
      showStopping: false,
      timer: null,
      resendTime: 0,
      sourceObject: null,
      receiverObject: null,
      dialogVisible: false,
      deveiceType: 'T',
      buttonText: this.parentComponent ? 'lang.goLive' : 'lang.Take',
      alertMessage: this.parentComponent ? 'lang.goLive' : 'lang.Take',
      selectDeviceList: this.deviceList,
      selectOhterDevice: this.hadSelectDevice,
      currentSocektData: [],
      loadingTimer: null,
      showFlag: false,
      confirmObj: {
        showFlag: false,
        name: '',
        peerId: '',
        sourceType: '',
        checkBehavior: true
      },
      allowClick: false,
      timeLockObj: {
        type: '',
        peerId: '',
        addPeerIds: '',
        deletePeerIds: [],
        deleteMainReceiver: false
      }
    }
  },
  mounted: function () {
    if (this.selectDeviceList) {
      this.getDeviceInfo(this.selectDeviceList)
      if ((this.selectType == 'LocalSDI' || !this.sourceObject) && this.flag === 'R' && this.receiverObject) this.getLocalSDIInfo()
    }
  },
  methods: {
    ...mapMutations('common', ['CURRENT_RID', 'BASE_USER_INFO']),
    confirmFun (flag) {
      if (this.confirmObj.sourceType && flag) this.confirmObj.showFlag = true
    },
    // 返回要按钮状态
    showBtnStatus (data) {
      if (this.selectOhterDevice) {
        // 说明是点击source,下拉项为R list.
        if (this.flag === 'T') {
          if (data && data.gridLivePeerId && this.receiverObject) {
            if (data.gridLivePeerId.includes(this.receiverObject.peerId)) {
              return 2
            } else {
              return 1
            }
          }
        } else {
          if (data && data.livePeerId && this.sourceObject) {
            if (data.livePeerId == this.sourceObject.peerId) {
              return 2
            } else {
              return 1
            }
          }
        }
      } else {
        if (!this.deviceList) return 0
        // Live on hold
        if (this.liveOnHoldFlag && this.currentSocektData.status == 1 && this.socketRInfo?.WorkMode == 1 && this.sourceObject) {
          if (this.socketRInfo.CurrentTStrIdHex.toLowerCase() == this.sourceObject.peerId) return 2
        }
        return this.sourceItem.status
      }
    },
    async checkInfo () {
      // pack、Grid、Anywhere需要弹出slug信息框
      this.confirmObj.peerId = ''
      this.confirmObj.sourceType = ''
      const arr = this.selectDeviceList.split(',')
      await this.axios.get(`/ccp/tvucc-device/device/getDevice?peerId=${arr[0]}`).then(res => {
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          const sourceType = result.type.toLowerCase()
          const platform = Number(result.platform)
          if (sourceType == 'x' || sourceType == 'tvugrid') {
            this.confirmObj.sourceType = 'Grid'
          }
          if (sourceType == 't' && platform) this.confirmObj.sourceType = platform >= 10 && platform <= 14 ? 'Anywhere' : 'Pack'
          this.confirmObj.name = result.name
          this.confirmObj.peerId = this.confirmObj.sourceType ? arr[0].toLowerCase() : ''
        }
      }).catch(() => {})
    },
    // 准备发送start live
    async startVideo () {
      // 只有全球区才开放此功能
      if (this.openOnGoingEvent) {
        try {
          await this.checkInfo()
        } catch (err) {
        }
      }
      // 根据id去拿设备信息
      if (!this.$refs.liveBtn) return
      const btnInfo = this.$refs.liveBtn.$parent.$el.innerText
      if (btnInfo == '停止' || btnInfo == 'Stop') {
        const params = {
          pn: 'shareTransmission'
        }
        this.axios.post('/ccp/tvucc-user/userDevice/listPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
          if (res.data.errorCode == '0x0') {
            if ((res.data.result && res.data.result[0] && res.data.result[0].content == 'true')) {
              this.showFlag = true
            } else {
              if (this.confirmObj.peerId) this.confirmObj.showFlag = true
              this.stopOption(btnInfo)
            }
          } else {
            if (this.confirmObj.peerId) this.confirmObj.showFlag = true
          }
        }).catch(() => {
          if (this.confirmObj.peerId) this.confirmObj.showFlag = true
          this.stopOption(btnInfo)
        })
      } else {
        if (this.confirmObj.peerId) this.confirmObj.showFlag = true
        this.stopOption(btnInfo)
      }
    },
    async stopOption (btnInfo) {
      // 先判断是否有选择过其他设备
      if (!this.selectDeviceList || this.allowClick) return
      Bus.$emit('hadClickedBtn')
      const sourceStatus = this.sourceItem.status
      if (btnInfo == this.$t('lang.Take') || btnInfo == this.$t('lang.goLive')) {
        const feature = this.$store.state.common.baseUserInfo.userFeatures
        let canStart = true // 是否有startLive的权限
        feature.map((item, index) => {
          const featureName = item.feature
          if (featureName == 'startLive') {
            if (item.value != 1) {
              canStart = false
            }
          }
        })
        if (!canStart) { // 无权限
          this.$message({
            type: 'warning',
            message: this.$t('lang.notHaveStartauthor')
          })
          return false
        }
        if (sourceStatus == 3) {
          this.$message.error(this.$t('lang.cameraOff'))
        } else {
          // 现获取所选择的source和receiver的状态，为了后面弹提示用.
          await this.getDeviceInfo(this.selectDeviceList)
          if ((this.selectType == 'LocalSDI' || !this.sourceObject) && this.flag === 'R' && this.receiverObject) {
            await this.getLocalSDIInfo()
          }
          const sourceType = this.sourceObject.type
          if (!sourceType) return
          if (this.flag === 'R') {
            if (this.sourceObject.status == 2 && this.sourceObject.type != 'X' && this.sourceObject.type != 'YouTube' && this.sourceObject.type != 'Ext') {
              this.$confirm(`${this.$t('lang.ReceiverDeviceOnLine1')}:${this.sourceObject.livePeerName}.${this.$t('lang.ReceiverDeviceOnLine2')}`, this.$t('lang.modelTips'), {
                confirmButtonText: this.$t('lang.confirm'),
                cancelButtonText: this.$t('lang.cancel'),
                center: true,
                type: 'warning',
                callback: action => {
                  if (`${action}` == 'confirm') {
                    if (sourceType == 'T') {
                      this.sendStopVideo(true)
                    } else {
                      this.sendStartVideo()
                    }
                  }
                }
              })
            } else {
              this.sendStartVideo()
            }
          } else {
            if (this.sourceObject.status == 2 && this.sourceObject.type == 'T') {
              this.$confirm(`${this.$t('lang.ReceiverDeviceOnLine1')}:${this.sourceObject.livePeerName}.${this.$t('lang.ReceiverDeviceOnLine2')}`, this.$t('lang.modelTips'), {
                confirmButtonText: this.$t('lang.confirm'),
                cancelButtonText: this.$t('lang.cancel'),
                center: true,
                type: 'warning',
                callback: action => {
                  if (`${action}` == 'confirm') {
                    this.sendStopVideo(true)
                  }
                }
              })
            } else {
              if (this.receiverObject.status == 2) {
                this.$confirm(`${this.$t('lang.ReceiverOnLive1')}:${this.receiverObject.livePeerName}.${this.$t('lang.ReceiverOnLive2')}`, this.$t('lang.modelTips'), {
                  confirmButtonText: this.$t('lang.confirm'),
                  cancelButtonText: this.$t('lang.cancel'),
                  center: true,
                  type: 'warning',
                  callback: action => {
                    if (`${action}` == 'confirm') {
                      this.sendStartVideo()
                    }
                  }
                })
              } else {
                this.sendStartVideo()
              }
            }
          }
        }
      } else if (btnInfo == this.$t('lang.Stop')) {
        const feature = this.$store.state.common.baseUserInfo.userFeatures
        let canStop = true // 是否有stopLive的权限
        feature.map((item, index) => {
          const featureName = item.feature
          if (featureName == 'stopLive') {
            if (item.value != 1) {
              canStop = false
            }
          }
        })
        if (!canStop) { // 无权限
          this.$message({
            type: 'warning',
            message: this.$t('lang.notHaveStoptauthor')
          })
          return false
        }
        await this.getDeviceInfo(this.selectDeviceList)
        if ((this.selectType == 'LocalSDI' || !this.sourceObject) && this.flag === 'R' && this.receiverObject) await this.getLocalSDIInfo()
        this.sendStopVideo()
      }
    },
    getLocalSDIInfo () {
      return new Promise((resolve, reject) => {
        const params = {
          rid: this.receiverObject.peerId.toLowerCase(),
          gLink: false,
          rTil: false, // this.typeData[10].isChecked
          localSdi: true,
          pageSize: 10,
          pageNum: 1,
          condition: this.selectDeviceList.split(',')[0],
          syncWebR: true
        }
        this.axios.post(`/ccp/tvucc-user/userDevice/pageRExtendExternalSource?${Qs.stringify(params)}`, '',
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(res => {
          if (res.data.errorCode == '0x0' && res.data.result && res.data.result.sourceLocalSDI && res.data.result.sourceLocalSDI.content && res.data.result.sourceLocalSDI.content.list.length) {
            this.sourceObject = res.data.result.sourceLocalSDI.content.list[0]
          }
          resolve()
        }).catch(() => {})
      })
    },
    // get had selected source and receiver info
    getDeviceInfo (peerList) {
      this.sourceObject = null
      this.receiverObject = null
      return new Promise((resolve, reject) => {
        if (!peerList || typeof peerList !== 'string') return
        const data = {
          peerIds: peerList.replaceAll(';', ',')
        }
        this.axios.get(MySource().deviceInfo.getDeviceInfo, { params: data }).then(res => {
          if (res.status === 200 && res.data.errorCode === '0x0') {
            const result = res.data.result
            result.map((v, i) => {
              const type = v.type
              type === 'R' ? (this.receiverObject = v) : (this.sourceObject = v)
            })
          } else {
            this.$message({
              message: res.data.errorInfo,
              type: 'warning'
            })
          }
          resolve()
        }).catch(() => {})
      })
    },
    // send start video
    sendStartVideo () {
      mySourceAnalyze('mySourceBtnLive', this.$route.path) // 谷歌统计
      myReceiversAnalyze('myReceiversPreviewVideoOpen', this.$route.path) // 谷歌统计 receiver start
      this.showLoading = true
      this.allowClick = true
      if (this.loadingTimer) clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        this.showLoading = false
        this.allowClick = false
      }, 10000)
      let params = {}
      if (this.flag === 'T') {
        if (!this.receiverObject) return
        params = {
          rid: this.receiverObject.peerId.toLowerCase(),
          sourceId: this.sourceItem.peerId.toLowerCase()
        }
      } else {
        if (this.selectType == 'LocalSDI' || this.sourceObject && this.sourceObject.type == 'LocalSDI') {
          this.startSDI()
          return
        }
        if (!this.sourceItem || !this.sourceObject) return
        params = {
          rid: this.sourceItem.peerId.toLowerCase(),
          sourceId: this.sourceObject.peerId.toLowerCase()
        }
      }
      this.axios.post(MySource().videoConfig.startVideo, params, { timeout: 90000 })
        .then(res => {
          this.handleStartResult(res)
        })
        .catch(() => {
          this.allowClick = false
        })
    },
    startSDI () {
      if (!this.receiverObject) return
      const startUrl = '/ccp/tvucc-device/receiver/startLiveExtendSource'
      const params = {
        rid: this.receiverObject.peerId.toLowerCase(),
        sourceId: this.sourceObject.peerId.toLowerCase(),
        type: this.sourceObject.type
      }
      this.axios.post(startUrl, params, { timeout: 90000 })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            this.handleStartResult(res)
          }
        }).catch(() => {
          this.allowClick = false
        })
    },
    async handleStartResult (res) {
      const errorCode = res.data.errorCode
      // timeLock相关
      // if (this.timeLocked && this.flag === 'R' && this.timeLockPage && !(errorCode == '81100107' || errorCode === '81100105' || errorCode === '81100108')) {
      //   if (this.selectOhterDevice) Bus.$emit('changeMultilControl', 'remove', this.timeLockObj)
      //   if (errorCode == '0x0') {
      //     Bus.$emit('changeMultilControl', 'add', {
      //       rId: this.receiverObject.peerId.toLowerCase(),
      //       sourceId: this.sourceObject.peerId.toLowerCase(),
      //       selectOtherDevice: this.selectOhterDevice
      //     })
      //   }
      // }
      if (res.status == 200 && errorCode === '0x0') {
        this.resendTime = 0
        this.selectOhterDevice = false
        this.showLoading = false
        const type = this.flag === 'T' ? this.sourceItem.type : this.sourceObject.type
        if (type == 'YouTube') {
          if (await this.analyzeUrl()) {
            this.$message({
              type: 'success',
              message: this.$t('lang.AnalyzingSuccess')
            })
            Bus.$emit('playVideo')
            // add emit for home page source page
            this.$emit('changeSourceRId', this.receiverObject.peerId.toLowerCase())
          }
        } else {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.$emit('changeSourceRId', this.receiverObject.peerId.toLowerCase())
          Bus.$emit('playVideo')
        }
        this.$emit('changeHadSelectDevice', false, this.sourceObject.peerId)
        this.CURRENT_RID(this.receiverObject.peerId.toLowerCase()) // 切换R时，提交最新的Rid
      } else if (errorCode == '81100107' || errorCode === '81100105' || errorCode === '81100108') {
        this.dealIssue(errorCode)
      } else {
        this.showLoading = false
        this.allowClick = false
        oUtils.ToTip(errorCode, 'warning', res.data.errorInfo)
        return false
      }
    },
    // 关闭直播
    sendStopVideo (flag) {
      mySourceAnalyze('mySourceBtnStop', this.$route.path) // 谷歌统计
      myReceiversAnalyze('myReceiversPreviewVideoClose', this.$route.path) // 谷歌统计 receiver stop
      const params = {
        rid: ''
      }
      if (flag && this.sourceObject && this.sourceObject.livePeerId) {
        params.rid = this.sourceObject.livePeerId.toLowerCase()
      }
      if (!flag && this.receiverObject) {
        params.rid = this.receiverObject.peerId.toLowerCase()
      }
      if (!params.rid) return
      this.showStopping = true
      this.allowClick = true
      if (this.loadingTimer) clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        this.showStopping = false
        this.allowClick = false
      }, 10000)
      this.axios.post(MySource().videoConfig.stopVideo, params, { timeout: 90000 })
        .then(res => {
          const errorCode = res.data.errorCode
          if (errorCode == '0x0') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.$emit('stopRState')
            this.selectOhterDevice = false
            this.showStopping = false
            // if (this.timeLocked && this.flag === 'R' && this.timeLockPage) {
            //   Bus.$emit('changeMultilControl', 'remove', this.timeLockObj)
            // }
            // Bus.$emit('restoreDefaultConfig', 'stop');
            if (flag) {
              this.sendStartVideo()
              if (this.State.drawDownSource) {
                if (this.State.drawDownSource.peerId != this.sourceItem.peerId) return
              }
              // 清除之前的websocket
              this.State.GetKernelStateObj = {}
              Bus.$emit('playVideo')
            } else {
              this.$emit('changeHadSelectDevice', false, this.sourceObject.peerId)
            }
          } else {
            this.showStopping = false
            this.allowClick = false
            oUtils.ToTip(errorCode, 'warning', res.data.errorInfo)
            return false
          }
        })
        .catch(() => {
          this.allowClick = false
        })
    },
    // 异常处理
    dealIssue (errorCode) {
      /*
      * 在cc上面添加的ext，播放时因为多了R同步数据的这一步，所以接口有可能返回设备不存在
      * 最多startlive4次，如果四次后还没有起起来，则视为startlive失败(8110010)
      * 针对当发生抢占的时候R那边会来不及处理(81100105,81100108)
      */
      if (this.resendTime < 4) {
        setTimeout(() => {
          this.resendTime++
          this.sendStartVideo()
        }, 1000)
      } else {
        this.showLoading = false
        this.allowClick = false
        this.resendTime = 0
        oUtils.ToTip(errorCode, 'warning')
        this.dialogVisible = false
        this.$emit('changeHadSelectDevice', false, this.sourceObject.peerId)
      }
    },
    async analyzeUrl () {
      this.$message({
        message: this.$t('lang.AnalyzingUrl')
      })
      return new Promise((resolve, reject) => {
        const url = this.flag === 'T' ? this.sourceItem.url : this.sourceObject.url
        const sourceId = this.flag === 'T' ? this.sourceItem.peerId : this.sourceObject.peerId
        const data = {
          rid: this.receiverObject.peerId,
          data: '{"IdHex":"' + sourceId + '","Url":"' + url + '"}',
          timeout: 30000
        }
        const params = Object.assign(data, receviceParams.youTobeAnalyze.analyzeUrl)
        this.axios.post(Receiver().youTobeAnalyze.analyzeUrl, params)
          .then(res => {
            res.data.result == 'True' ? resolve(true) : resolve(false)
          })
          .catch(msg => {
            resolve(false)
          })
      })
    }
    // msgCenter () {
    //   const h = this.$createElement
    //   let targetObj = null
    //   if (this.timeLockObj.type == 'oneMsg') {
    //     targetObj = h('p', { style: 'font-size: 14px;' }, [
    //       h('p', null, `${this.$t('lang.OneReceiverTip')}`),
    //       h('p', null, `${this.$t('lang.StillRemove')}`)
    //     ])
    //   } else if (this.timeLockObj.type == 'mainMsg') {
    //     targetObj = h('p', { style: 'font-size: 14px;' }, [
    //       h('p', { style: 'display: block;width:425px;' }, `${this.$t('lang.removeMainRTips')}`),
    //       h('p', null, `${this.$t('lang.StillRemove')}`)
    //     ])
    //   }
    //   this.$msgbox({
    //     title: '',
    //     message: targetObj,
    //     showCancelButton: true,
    //     confirmButtonText: this.$t('lang.confirmBtn'),
    //     cancelButtonText: this.$t('lang.Cancel'),
    //     callback: action => {
    //       if (action === 'confirm') {
    //         if (this.timeLockObj.type === 'mainMsg') {
    //           this.timeLockObj.deletePeerIds = this.timeLockedR
    //           this.timeLockObj.deleteMainReceiver = true
    //         }
    //         this.startVideo()
    //       }
    //     }
    //   })
    // }
  }
}
</script>
<style lang='less' scoped>
::v-deep .el-button {
  max-width: 74px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .el-button--success.is-disabled,
::v-deep .el-button--success.is-disabled:hover,
::v-deep .el-button--success.is-disabled:focus,
::v-deep .el-button--success.is-disabled:active {
  background: rgba(37, 37, 37, 1);
  border-color: rgba(37, 37, 37, 1);
  color: rgba(102, 102, 102, 1);
}
</style>
