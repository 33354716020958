import {
  config,
  deviceInfo,
  startOrStopVideo,
  userDevice
} from '@/api.config.js'

/* my source page action config */
export function MySource () {
  return {
    ConfigT: {
      getConfigUrl: config() // get config-T page url
    },
    deviceInfo: {
      getDeviceInfo: deviceInfo() + 'listDevice' // get had selected source or receiver status...
    },
    videoConfig: {
      startVideo: startOrStopVideo() + 'startLive', // live video
      stopVideo: startOrStopVideo() + 'stopLive' // stop video
    },
    DeviceList: {
      sourceList: userDevice() + 'listRPairSource', // get pair with Receiver source
      receiverList: userDevice() + 'listR' // accord source id to get pair Receiver
    }
  }
}

export default MySource
